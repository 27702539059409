import { createValidationSchema, isError, NotFoundError, PosixDate, runUnsafe, TaggedError, uuidField, } from "@for-it/domain-lib";
export class InvalidMediasArrayError extends TaggedError {
    constructor() {
        super("InvalidMediasArrayError");
    }
}
export const deleteProductMultimedia = {
    name: "deleteMedia",
    description: "With valid id, delete a media",
    isAuthRequired: true,
    requiredPermissions: [],
    payloadSchema: createValidationSchema({
        id: uuidField({
            message: "Debe ser un id valido",
            required: true,
        }),
        productId: uuidField({
            message: "Debe ser un id valido",
            required: true,
        }),
    }),
    useCase: async ({ medias, products }, { id, productId }) => {
        const foundProduct = await products.findById(productId);
        if (isError(foundProduct)) {
            return foundProduct;
        }
        const mediaInProduct = foundProduct.multimedias.find((mediaId) => mediaId === id);
        if (mediaInProduct === undefined)
            return new NotFoundError();
        const newArrayMedias = foundProduct.multimedias.filter((mediaId) => mediaId !== id);
        if (newArrayMedias.length === 0) {
            return new InvalidMediasArrayError();
        }
        else {
            if (id !== "4c51c2a2-6955-449e-b47b-a306f3612ff4") {
                await runUnsafe(medias.remove(id));
            }
            await products.update({
                ...foundProduct,
                multimedias: newArrayMedias,
                updatedAt: new PosixDate(Date.now()),
            });
        }
    },
};
